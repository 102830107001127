<template>
  <div class="content">
  <div class="row">
    <!-- Arriba -->
    <div class="col-6 display-tablet-none">
      <img src="@/assets/images/portada/portada_arriba_izq.png"/>
    </div>
    <div class="col-6 div-tablet-col-12" style="margin-left: -0.1px">
      <img src="@/assets/images/portada/portada_arriba_der.png"/>
    </div>

    <!-- Medio -->
    <div class="col-6 display-tablet-none">
      <img src="@/assets/images/portada/portada_img_arriba_izq.png"/>
    </div>
    <div class="col-3 div-tablet-col-6">
      <img src="@/assets/images/portada/portada_img_der1.png"/>
    </div>
    <div class="col-3 div-tablet-col-6">
      <a href="https://www.cleverauditbs.com" target="_blank">
        <img src="@/assets/images/portada/portada_img_der2.png"/>
      </a>
    </div>

    <!-- Abajo -->
    <div class="col-6 display-tablet-none">
      <div style="" class="div-background-imagen"></div>
    </div>
    <div class="col-6 div-tablet-col-12">
      <div
          class="q-pt-xl q-pl-lg div-abajo-der-text div-background-color"
      >
        <div class="text-subtitle2 q-mb-md text-weight-bold">
          CleverDocs | {{ $t("Cloud Solution") }}
        </div>
        <div class="q-mb-sm text-weight-medium">
          {{ $t("Version") }}:
          <span class="text-weight-regular">{{ version }}</span>
        </div>
        <div class="q-mb-sm text-weight-medium">
          {{ $t("Company") }}:
          <span v-if="user.client" class="text-weight-regular">{{user.client.name}}</span>
        </div>
        <div class="q-mb-sm text-weight-medium">
          {{ $t("User") }}:
          <span class="text-weight-regular">{{ user.name }}</span>
        </div>
        <div class="text-weight-medium">
          {{ $t("Date") }}:
          <span class="text-weight-regular">{{time}}</span>
        </div>

        <div class="div-text-contacto q-mt-xl">
          <a
              class="text-weight-medium link-contacto"
              href="https://www.cleverauditbs.com/contacto"
              target="_blank"
          >{{ $t("Contact CleverBusiness") }}</a
          >
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { computed } from "vue";
import {mapGetters} from "vuex";
export default {

  data() {
    return {
      timer: null,
      time: '',
      version: process.env.VUE_APP_VERSION
    };
  },
  methods: {
    countdown() {
      this.time ++;
      if(this.time==0){
        clearInterval(this.timer)
      }
    },
    getNow() {
      const current = new Date();

      const yr = current.getFullYear();
      const mnt =
          current.getMonth() + 1 < 9 ? "0" + (current.getMonth() + 1) : current.getMonth() + 1;
      const day = current.getDate() < 9 ? "0" + current.getDate() : current.getDate();
      //const date = current.getDate()+'-'+(current.getMonth()+1)+'-'+current.getFullYear();
      const date = day + "/" + mnt + "/" + yr;

      const h = current.getHours() <= 9 ? "0" + current.getHours() : current.getHours();
      const m = current.getMinutes() <= 9 ? "0" + current.getMinutes() : current.getMinutes();
      const s = current.getSeconds() <= 9 ? "0" + current.getSeconds() : current.getSeconds();
      //const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      const time = h+":"+m+":"+s;

      const dateTime = date +' '+ time;

      this.time = dateTime;
    }
  },
  setup() {
    /*const dateTime = ref(date.formatDate(Date.now(), "DD/MM/YYYY HH:mm:ss"));
    setInterval(() => {
      dateTime.value = date.formatDate(Date.now(), "DD/MM/YYYY HH:mm:ss");
    }, 1000);*/

    return {
      //Methods
      //Variables
      version: computed(() => process.env.VUE_APP_VERSION),
    };
  },

  computed: {
    ...mapGetters({
      user: 'login/user',
    })
  },
  mounted() {
    this.timer=setInterval(this.getNow, 1000);
  }
}
</script>
<style lang="sass" scoped>
.div-logo-mobile
  display: none

.div-background-imagen
  background-image: url("~@/assets/images/portada/portada_img_abajo_izq.png")
  position: fixed
  background-size: contain
  min-width: 50%
  min-height: 100%
  z-index: -1

.div-background-color
  background-color: #d7e8f8
  /*background-color: #f7f7f8*/
  position: fixed
  background-size: contain
  min-width: 50%
  min-height: 100%

.link-contacto
  color: black
  text-decoration: none

.link-contacto:hover
  color: #5d5d5d

.content img
  width: 100%

.content
  padding-right: 0
  padding-left: 0
  margin-top: 27px

.text-subtitle2
  font-size: .875rem
  font-weight: 500
  letter-spacing: .00714em
  line-height: 1.375rem

.text-weight-bold
  font-weight: 800

.text-weight-medium
  font-weight: 550

.text-weight-regular
    font-weight: 400

.q-mb-sm
  margin-bottom: 8px

.q-pt-xl
  padding-top: 48px

.q-pl-lg
  padding-left: 24px

.q-mt-xl
  margin-top: 48px

.q-mb-md
  margin-bottom: 16px

.row
  margin: 0px

.col-6
  padding: 0
.col-3
  padding: 0
@media (max-width: 1320px)
  .div-abajo-der-text
    padding-top: 15px
  .div-text-contacto
    margin-top: 15px
  .col-6
    padding: 0

@media (max-width: 1020px)
  .display-tablet-none
    display: none
  .div-tablet-col-6
    flex: 0 0 50%
    max-width: 50%

    width: 50% !important
    padding: 0
  .div-tablet-col-12
    flex: 0 0 100%
    max-width: 100%
    width: 100%
  .div-background-color
    min-width: 100%
    position: unset
    padding-bottom: 17px

@media (max-width: 590px)
  .div-logo-mobile
    display: block
</style>

