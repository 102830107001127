var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-6 div-tablet-col-12"},[_c('div',{staticClass:"q-pt-xl q-pl-lg div-abajo-der-text div-background-color"},[_c('div',{staticClass:"text-subtitle2 q-mb-md text-weight-bold"},[_vm._v(" CleverDocs | "+_vm._s(_vm.$t("Cloud Solution"))+" ")]),_c('div',{staticClass:"q-mb-sm text-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Version"))+": "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.version))])]),_c('div',{staticClass:"q-mb-sm text-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Company"))+": "),(_vm.user.client)?_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.user.client.name))]):_vm._e()]),_c('div',{staticClass:"q-mb-sm text-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("User"))+": "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.user.name))])]),_c('div',{staticClass:"text-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Date"))+": "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.time))])]),_c('div',{staticClass:"div-text-contacto q-mt-xl"},[_c('a',{staticClass:"text-weight-medium link-contacto",attrs:{"href":"https://www.cleverauditbs.com/contacto","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("Contact CleverBusiness")))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 display-tablet-none"},[_c('img',{attrs:{"src":require("@/assets/images/portada/portada_arriba_izq.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 div-tablet-col-12",staticStyle:{"margin-left":"-0.1px"}},[_c('img',{attrs:{"src":require("@/assets/images/portada/portada_arriba_der.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 display-tablet-none"},[_c('img',{attrs:{"src":require("@/assets/images/portada/portada_img_arriba_izq.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 div-tablet-col-6"},[_c('img',{attrs:{"src":require("@/assets/images/portada/portada_img_der1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 div-tablet-col-6"},[_c('a',{attrs:{"href":"https://www.cleverauditbs.com","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/portada/portada_img_der2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 display-tablet-none"},[_c('div',{staticClass:"div-background-imagen"})])
}]

export { render, staticRenderFns }